import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames';

import logo from '../images/notthinkablelogo.png'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { open } = this.state;
    const hamburgerClasses = classNames({
      'hamburger': true,
      'hamburger-clicked': open,
    });
    const brandClasses = classNames({
      'brand': true,
      'brand-with-shadow': !open,
    })

    return <div>
      <div className={brandClasses}>
        <div className="brand-container">
          <Link
            className="logo-link"
            to={'/'}>
              <img alt="logo" className="logo-image" src={logo}/>
          </Link>
          <div role="menu" className="hamburger-menu"
            onKeyDown={() => this.setState({ open: !open })}
            onClick={() => this.setState({ open: !open })}
            tabIndex={0}
          >
            <nav className={hamburgerClasses}>
              <div className="hamburger-line-1"></div>
              <div className="hamburger-line-2"></div>
              <div className="hamburger-line-3"></div>
            </nav>
          </div>
          <div className="navigation-menu">
            <Link className="navigation-menu-item" to="/about">
              About
            </Link>
            <Link className="navigation-menu-item" to="/undevelopment">
              Undevelopment
            </Link>
          </div>
        </div>
      </div>
      {
        open && <div className="slider-menu">
          <Link className="slider-menu-item" to="/about">
            About
          </Link>
          <Link className="slider-menu-item" to="/undevelopment">
            Undevelopment
          </Link>
        </div>
      }
    </div>
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
